import axios from 'axios';
import { Message } from 'element-ui';
axios.defaults.timeout = 10000;
// import isDownUrl from '@/common/downUrl';
let $http = axios.create({
	baseURL: RESURL,
	timeout: 30000,
	headers: { 'Content-Type': 'application/json' },
});
$http.interceptors.request.use(
	(config) => {
		config.url=config.url.replace("http", "https")
		// debugger
		//token验证
		const token = JSON.parse(sessionStorage.getItem('token'));
		if (token) {
			config.headers.token = token;
		}
		return config;
	},
	(err) => {
		return Promise.reject(err);
	}
);
//响应拦截
$http.interceptors.response.use(
	(response) => {
		if (response.headers.token) {
			sessionStorage.setItem('token', response.headers.token);
		}
		if (response.status === 200 || response.status === 201) {
			if (response.data.success) {
				return Promise.resolve(response);
			} else if (response.config.responseType == 'blob') {
				return Promise.resolve(response);
			} else {
				switch (response.data.status) {
					case 404:
						Message({
							message: '网络异常',
							type: 'error',
						});
						break;
					case 500:
						Message({
							message: '服务器异常，请联系管理员',
							type: 'error',
						});
						break;
					case 401:
						Message({
							message: '登录未授权,请重新登录！',
							type: 'error',
						});
						this.$router.push('/login');
						break;
					default:
						Message({
							message: response.data.message || response.data || '网络异常',
							type: 'error',
						});
						break;
				}
			}
		}
	},
	(error) => {
		// 对响应错误做点什么
		if (error.message.indexOf('timeout') != -1) {
			Message.error('网络超时');
		} else if (error.message == 'Network Error') {
			Message.error('网络连接错误');
		} else if (error.response.data.code == '403') {
			Message.error(error.response.data.message);
			sessionStorage.clear();
			setTimeout(function () {
				location.href = '/login';
			}, 1000);
		} else {
			Message.error(error.response.data.message);
		}
		return Promise.reject(error);
	}
);

export default $http;
