import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import $http from './request/https';
import { Session } from '@/utils/storage';
const api = Session.get('apiInfo');
import Particles from 'vue-particles';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/theme/index.scss';
import '@/permission';
import '@/utils/gl-martix-0.9.5.min.js';
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

import { globalComponentSize } from '@/utils/componentSize.js';
// 全局引入图片验证码组件
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);
import VueAMap from 'vue-amap';
// 初始化高德地图的 key 和插件
VueAMap.initAMapApiLoader({
	key: 'bc8dc4be8df865450010661fdf682155',
	plugin: [
		'AMap.Autocomplete',
		'AMap.PlaceSearch',
		'AMap.Scale',
		'AMap.OverView',
		'AMap.ToolBar',
		'AMap.MapType',
		'AMap.PolyEditor',
		'AMap.CircleEditor',
		'AMap.Geocoder',
		'AMap.Geolocation',
    'AMap.MarkerClusterer'
	],
	v: '1.4.4',
});
Vue.use(VueAMap);
Vue.use(Particles);
Vue.use(Element, {
	size: globalComponentSize,
});
Vue.config.productionTip = false;
Vue.prototype.$http = $http;
Vue.prototype.api = api;
Vue.prototype.PATH = RESURL;
Vue.prototype.bus = new Vue();

export default new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
